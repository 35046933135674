<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            :items="vendors"
            item-text="name"
            item-value="id"
            v-model="params.vendorId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-select
            outlined
            flat
            dense
            background-color="white"
            :items="statuses"
            v-model="params.status"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-btn @click="search" color="primary">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">A/P Type</th>
              <th class="text-center">Invoice #</th>
              <th class="text-center">Invoice Date</th>
              <th class="text-center">SPK#</th>
              <th class="text-center">DO#</th>
              <th class="text-center">Faktur Pajak</th>
              <th class="text-center">Vendor Name</th>
              <th class="text-center">No</th>
              <th class="text-center">Description</th>
              <th class="text-center">Qty</th>
              <th class="text-center">U/M</th>
              <th class="text-center">Curr</th>
              <th class="text-center">Unit Price</th>
              <th class="text-center">PPN (in IDR)</th>
              <th class="text-center">Due Date</th>
              <th class="text-center">Site</th>
              <th class="text-center">PO Customer#</th>
              <th class="text-center">Payment Date</th>
              <th class="text-center">Payment</th>
              <th class="text-center">Balance</th>
              <th class="text-center">Age fr Due</th>
              <th class="text-center">Remark</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in otherApDetail.items" :key="item">
              <td>{{ item.type }}</td>
              <td>{{ item.invoiceNumber }}</td>
              <td>{{ item.invoiceDate }}</td>
              <td></td>
              <td></td>
              <td>{{ item.taxInvoice }}</td>
              <td>{{ item.vendorName }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ item.balance }}</td>
              <td>{{ item.ageForDue }}</td>
              <td>{{ item.remark }}</td>
              <td>{{ item.status }}</td>
            </tr>
            <tr>
              <td colspan="19" class="text-right">Total Invoice {{ otherApDetail.vendorName }}</td>
              <td>{{ otherApDetail.total }}</td>
            </tr>
            <tr>
              <td colspan="19" class="text-right">Total A/P: {{ otherApDetail.vendorName }}</td>
              <td>{{ otherApDetail.total }}</td>
            </tr>
            <tr>
              <td colspan="19" class="text-right">Total Other A/P Type:</td>
              <td>{{ otherApDetail.total }}</td>
            </tr>
            <tr>
              <td colspan="19" class="text-right">Total Other A/P</td>
              <td>{{ otherApDetail.total }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "report-other-ap-detail",
  data() {
    return {
      menuStartDate: false,
      params: {
        vendorId: null,
        date: moment().format("yyyy-MM-DD"),
        status: "all",
      },
      statuses: ["all", "outstanding", "paid"],
    };
  },
  computed: {
    ...mapState("external", ["vendors"]),
    ...mapState("report", ["otherApDetail"]),
  },
  methods: {
    search() {
      this.$store.dispatch("report/otherApDetail", this.params);
    },
  },
  mounted() {
    this.$store.dispatch("external/getVendors");
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
